import styled from "styled-components"

import { colors } from "../../../styles/jscolors"
// import { breakpoints, sizes } from "../../../styles/breakpoints"

import { Form as FormDefault } from "../"

export const Form = styled(FormDefault)`
  .form-group {
    /* .label {
      
    } */

    .form-control {
      border-radius: 2px;
      background-color: ${colors.grayscale3};
      font-size: 0.85rem;
    }
  }
`
