import * as React from "react"
// import { Link } from "gatsby"

import { Container } from "../../../styles/container"
import { SectionContent } from "../"
import FormResetPassword from "../../Form/ResetPassword"
import CardAuth from "../../Card/Auth"

import * as S from "./styled"

const SectionResetPassword = () => (
  <S.Section>
    <Container>
      <SectionContent className="s-content">
        <CardAuth className="card">
          <div className="c-header">
            <h3 className="c-title">Definir nova senha</h3>
            <FormResetPassword />
          </div>
        </CardAuth>
      </SectionContent>
    </Container>
  </S.Section>
)

export default SectionResetPassword
