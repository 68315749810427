import React from "react"
import { useQueryParam, StringParam } from "use-query-params"
import { Formik } from "formik"
import * as Yup from "yup"
import axios from "axios"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import { navigate } from "gatsby"

import { Button } from "../../Button"
import * as S from "./styled"

const FormResetPassword = () => {
  const [code] = useQueryParam("code", StringParam)
  const SweetAlert = withReactContent(Swal)

  console.log(code)

  const initialValues = {
    code: code,
    password: "",
    passwordConfirmation: "",
  }

  const validationSchema = Yup.object().shape({
    password: Yup.string().required("Campo obrigatório"),
    passwordConfirmation: Yup.string()
      .required("Campo obrigatório")
      .test(
        "passwords-match",
        "As senhas precisam ser iguais.",
        function (value) {
          return this.parent.password === value
        }
      ),
  })

  const formOnSubmit = async (values, { resetForm }) => {
    try {
      // eslint-disable-next-line no-unused-vars
      const response = await axios({
        method: "post",
        url: "https://ruy-molina-back.herokuapp.com/auth/reset-password",
        data: values,
      })

      // console.log(responseData)
      SweetAlert.fire({
        title: "Sucesso!",
        text: "Sua senha foi alterada com sucesso!",
        icon: "success",
        showCloseButton: false,
        showConfirmButton: false,
        timer: 2000,
      })
      resetForm()
      setTimeout(() => {
        navigate("/")
      }, 2000)
    } catch (error) {
      SweetAlert.fire({
        title: "Oops...",
        text: "Algo deu errado, tente novamente mais tarde.",
        icon: "error",
        showCloseButton: false,
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      })
      resetForm()
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={formOnSubmit}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({
        handleSubmit,
        isSubmitting,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
      }) => (
        <S.Form autoComplete="off" onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="label" htmlFor="password">
              Nova senha
            </label>
            <input
              className={`form-control ${
                touched.password && errors.password ? "-is-invalid" : ""
              }`}
              id="password"
              name="password"
              placeholder="Digite a nova senha"
              type="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {touched.password && errors.password && (
              <span className="error-message">{errors.password}</span>
            )}
          </div>

          <div className="form-group">
            <label className="label" htmlFor="passwordConfirmation">
              Confirmar nova senha
            </label>
            <input
              className={`form-control ${
                touched.passwordConfirmation && errors.passwordConfirmation
                  ? "-is-invalid"
                  : ""
              }`}
              id="passwordConfirmation"
              name="passwordConfirmation"
              placeholder="Digite novamente a nova senha"
              type="password"
              value={values.passwordConfirmation}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {touched.passwordConfirmation && errors.passwordConfirmation && (
              <span className="error-message">
                {errors.passwordConfirmation}
              </span>
            )}
          </div>

          <div className="form-group">
            <Button
              type="submit"
              theme="primary"
              full
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Alterar senha
            </Button>
          </div>
        </S.Form>
      )}
    </Formik>
  )
}

export default FormResetPassword
