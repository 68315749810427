import * as React from "react"

import Seo from "../components/seo"
import Layout from "../components/Layout"
import SectionResetPassword from "../components/Section/SectionResetPassword"
import WppButtonFloat from "../components/Button/WppButtonFloat"

const ResetPasswordPage = () => (
  <Layout>
    <Seo title="Alterar senha" />
    <SectionResetPassword />
    <WppButtonFloat />
  </Layout>
)

export default ResetPasswordPage
