import styled from "styled-components"

import { Section as SectionComponent } from "../"
import { breakpoints, sizes } from "../../../styles/breakpoints"

export const Section = styled(SectionComponent)`
  padding: 0 0 100px;
  @media ${breakpoints.lessThan(sizes.tablet)} {
    padding: 0 0 60px;
  }

  .s-content {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 50px;
    /* border: 1px solid red; */
    @media ${breakpoints.lessThan(sizes.tablet)} {
      flex-direction: column;
    }

    .form-contact {
      flex: 1;
      margin-right: 80px;
      /* border: 1px solid red; */
      @media ${breakpoints.lessThan(sizes.desktop)} {
        margin-right: 40px;
      }
      @media ${breakpoints.lessThan(sizes.tablet)} {
        margin-right: 0;
        flex: unset;
        width: 100%;
      }

      .title {
        font-size: 1.3rem;
        margin-bottom: 10px;
      }
    }

    .links-contact {
      flex: 1;
      /* border: 1px solid black; */

      .box-contact {
        &:not(:last-of-type) {
          margin-bottom: 30px;
        }

        .title {
          font-size: 1.1rem;
          margin-bottom: 10px;
        }

        .btn-wrapper {
          display: flex;
          flex-direction: column;
          width: max-content;

          .btn {
            &:not(:last-of-type) {
              margin-bottom: 8px;
            }
          }
        }

        &.-app {
          .btn-wrapper .btn {
            text-transform: uppercase;
          }
        }
      }

      @media ${breakpoints.lessThan(sizes.tablet)} {
        margin-top: 45px;
        flex: unset;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 35px 50px;

        .box-contact {
          flex: 1 auto calc(50% - 50px);
          /* border: 1px solid red; */

          &:not(:last-of-type) {
            margin-bottom: unset;
          }

          .title {
            text-align: center;
          }

          /* &:nth-of-type(2) {
            order: 3;
          }
          &:nth-of-type(4) {
            order: 4;
          } */
        }
      }
    }
  }
`
